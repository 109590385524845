var Tegeldepot = Tegeldepot || {};

Tegeldepot.ToggleLink = class {
    constructor(options) {
        this.hookEvents = this.hookEvents.bind(this);
        this.toggleOverlay = this.toggleOverlay.bind(this);

        this.options = jQuery.extend({}, {
            linkSelector: '.toggle-link',
            overlaySelector: '.overlay',
            overlayHtml: '<div class="overlay"></div>',
            bodyClass: false,
            scrollLockTarget: false
        }, options);

        this.hookEvents();
    }

    toggleOverlay() {
        const overlaySelector = jQuery(this.options.overlaySelector);
        const overlayHtml = jQuery(this.options.overlayHtml);

        if (overlaySelector.length) {

            overlaySelector.removeClass('show');

            setTimeout(() => {
                overlaySelector.remove();
            }, 150);

        } else {
            overlayHtml.appendTo('body');

            setTimeout(() => {
                jQuery(this.options.overlaySelector).addClass('show');
            }, 2);

        }
    }

    hookEvents() {
        jQuery('body').on('click', this.options.linkSelector, (event) => {
            event.preventDefault();

            const link = jQuery(event.currentTarget);
            const targetElement = link.attr('data-target-element') || link.attr('href');

            if (link.hasClass('with-overlay')) {
                this.toggleOverlay();

                if (link.hasClass('overlay-transparent')) {
                    jQuery(this.options.overlaySelector).addClass('transparent');
                }

                jQuery('body').on('click', this.options.overlaySelector, () => {
                    link.toggleClass('active');
                    jQuery(targetElement).toggleClass('active');
                    this.toggleOverlay();

                    jQuery('body').off('click', this.options.overlaySelector);
                });
            }

            link.toggleClass('active');
            jQuery(targetElement).toggleClass('active');

            if (this.options.bodyClass && this.options.scrollLockTarget) {
                if (jQuery(targetElement).hasClass('active')) {
                    jQuery('body').addClass(this.options.bodyClass);
                    bodyScrollLock.disableBodyScroll(this.options.scrollLockTarget);
                } else {
                    jQuery('body').removeClass(this.options.bodyClass);
                    bodyScrollLock.enableBodyScroll(this.options.scrollLockTarget);
                }
            }
        });
    }

};

jQuery(function () {
    new Tegeldepot.ToggleLink();
});